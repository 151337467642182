/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.8
 *
*/

// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

// Glyphicons from Bootstrap 3.3.7
@import "import/glyphicons.scss";

// Variables, Mixins
@import "import/variables.scss";
@import "import/mixins.scss";

// INSPINIA Theme Elements
@import "import/typography.scss";
@import "import/navigation.scss";
@import "import/top_navigation.scss";
@import "import/buttons.scss";
@import "import/badges_labels.scss";
@import "import/elements.scss";
@import "import/sidebar.scss";
@import "import/base.scss";
@import "import/pages.scss";
@import "import/chat.scss";
@import "import/metismenu.scss";
@import "import/spinners.scss";

// Landing page styles
@import "import/landing.scss";

// RTL Support
@import "import/rtl.scss";

// For demo only - config box style
@import "import/theme-config.scss";

// INSPINIA Skins
@import "import/skins.scss";
@import "import/md-skin.scss";

// Media query style
@import "import/media.scss";

// Custom style
// Your custom style to override base style
@import "import/custom.scss";

// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }

  body { overflow: visible !important; }

  #page-wrapper {
    margin: 0;
  }
}














